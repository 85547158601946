import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { ReactNode } from 'react';

type Props = {
    title: string;
    children: ReactNode;
};

export const ColorsAccordionWrapper = ({ title, children }: Props) => (
    <AccordionItem>
        <AccordionButton
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            textAlign="left"
        >
            <Text fontSize="18px" color="#141414">
                {title}
            </Text>
            <AccordionIcon color="#141414" />
        </AccordionButton>
        <AccordionPanel borderTopWidth={1}>{children}</AccordionPanel>
    </AccordionItem>
);
