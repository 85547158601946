import { MdClose, MdPriorityHigh } from '@usercentrics/cookiebot-ui-react';

import { RecommendedAction } from '../api/generated-types';

type StatusMap = {
    [key: string]: {
        label: string;
        color: string;
        header: string;
        icon: any;
        complianceStatusColor: string;
    };
};

export enum StatusEnum {
    Done = 'done',
    Pending = 'pending',
    Failed = 'failed',
    DoneLowRisk = 'doneLowRisk',
    DoneAtRisk = 'doneAtRisk'
}

export const StatusEnumMap: Record<string, string> = {
    [StatusEnum.Done]: 'done',
    [StatusEnum.Pending]: 'pending',
    [StatusEnum.Failed]: 'failed',
    [StatusEnum.DoneLowRisk]: 'doneLowRisk',
    [StatusEnum.DoneAtRisk]: 'doneAtRisk'
};

enum complianceEnum {
    atRisk = 'common.atRisk',
    lowRisk = 'common.lowRisk'
}

export enum complianceKeys {
    atRisk = 'atRisk',
    lowRisk = 'lowRisk'
}

export const complianceStatusMap: StatusMap = {
    [complianceKeys.atRisk]: {
        label: complianceEnum.atRisk,
        icon: MdClose,
        color: '#9f1819',
        complianceStatusColor: '#CB1F1F',
        header: 'reportPage.atRiskHeader'
    },
    [complianceKeys.lowRisk]: {
        label: complianceEnum.lowRisk,
        icon: MdPriorityHigh,
        color: '#0B9666',
        complianceStatusColor: '#0A9667',
        header: 'reportPage.lowRiskHeader'
    }
};

export enum ComponentTypeEnum {
    wixStandardComponent = 'common.wixStandardComponent',
    thirdPartyApp = 'common.thirdPartyApp',
    otherServices = 'common.otherServices',
    customEmbeds = 'common.customEmbeds'
}

export const componentTypeSortOrder: { [key: string]: number } = {
    wixStandardComponent: 1,
    wixCustomComponent: 2,
    thirdPartyApp: 3,
    customEmbeds: 4
};

export const recommendedActionsMap: { [key: string]: string } = {
    [RecommendedAction.ReportOrRemoval]: 'common.reportOrRemoval',
    [RecommendedAction.CategoryChange]: 'common.reportOrRemoval',
    [RecommendedAction.EnablePlaceholders]:
        'reportPage.recommendedAction.enablePlaceholders'
};

export enum Colors {
    Warning = '#DE8E09',
    Error = '#9F1818',
    Success = '#076646',
    SuccessLight = '#0B9666',
    DefaultBlue = '#1A32CF'
}

export enum Texts {
    ActionRequired = 'common.actionRequired',
    Error = 'common.error'
}

export const COMPONENTS_LIMIT = 5;

export enum FeedbackModalView {
    Default = 'default',
    ThumbsUp = 'thumbs-up',
    ThumbsDown = 'thumbs-down'
}

export const Links = {
    FAQ: 'https://wix-support.cookiebot.com/hc/en-us/categories/12337584372892-WIX-Customer',
    OpenTicket: 'https://wix-support.cookiebot.com/hc/en-us/requests/new',
    GetHelp: 'https://wix-support.cookiebot.com/hc/en-us',
    LeaveReview: `https://www.wix.com/app-market/add-review/${
        import.meta.env.VITE_APP_ID
    }`
};
