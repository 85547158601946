export const LogoBlack = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 1824.4 245.9"
        width="200"
    >
        <g>
            <g id="Layer_1">
                <g id="Design">
                    <path d="M334,3.8v181.6c0,13.9,4.2,21.3,14.7,21.3s14.5-8.5,14.5-21.3V3.8h46.4v178.7c0,42.3-19.4,63.2-61.6,63.2s-60.2-20.2-60.2-63.6V3.8h46.2Z" />
                    <path d="M474.2,168.8v16.5c0,15.7,3.9,23,14.4,23s13-10.8,13-22.1c0-22.5-4.2-29-28.6-49.4-26.9-22.8-40.7-36.2-40.7-71.4S443.7,0,488.8,0s55.1,32.7,55.1,60v13.7h-43.3v-14.3c0-14.5-2.4-21.6-11.4-21.6s-11.2,7.3-11.2,20.8,2.6,21.5,22.3,36.8c35.1,27.6,47.7,43,47.7,82.3s-12.8,68.1-60.1,68.1-58.5-27.4-58.5-62.8v-14.3h44.9,0Z" />
                    <path d="M663.1,137h-49.2v65h57.6l-5.5,40.3h-98.1V3.8h97.7v40.6h-51.8v52.3h49.2v40.3h0Z" />
                    <path d="M733.8,138.6v103.7h-45.8V3.8h59.5c40,0,59.7,17.6,59.7,59.3v8.6c0,34-13.6,44.1-23.8,48.9,14.8,7.2,21.8,18.1,21.8,51.4s-.4,57.8,1.6,70.4h-44.4c-3-10.8-2.7-41.8-2.7-72.3s-3.1-31.4-20.5-31.4h-5.4ZM733.9,102.5h5.7c15.3,0,21.6-4.8,21.6-27v-11.5c0-16-3.2-24-20.1-24h-7.2v62.5Z" />
                    <path d="M946.1,164.5v12.9c0,30.1-5.5,68.5-60.2,68.5s-57.9-21.5-57.9-65.4V63.6c0-41.5,20.7-63.3,58.9-63.3s58.6,33.2,58.6,64.4v15h-46.2v-20.4c0-13.1-2.8-19.8-12.4-19.8s-12.5,6.3-12.5,19.8v126.1c0,12.7,2.1,21.4,12.5,21.4s12.9-7.3,12.9-22.5v-19.8h46.4Z" />
                    <path d="M1060.9,137h-49.2v65h57.6l-5.5,40.3h-98.1V3.8h97.7v40.6h-51.8v52.3h49.2v40.3h0Z" />
                    <path d="M1085.8,242.3V3.8h50.8c7.6,29.1,31.5,137.1,33.4,147.6h1.1c-2.5-31.7-4.2-75.1-4.2-108.4V3.8h42.6v238.5h-51.2c-4.9-22.7-31-146.1-32.6-153.7h-1.2c1.8,28.1,3.2,75.5,3.2,113v40.7h-41.8Z" />
                    <path d="M1260.1,44.4h-34.7V3.8h115.5v40.6h-35v198h-45.8V44.4h0Z" />
                    <path d="M1402.9,138.6v103.7h-45.8V3.8h59.5c40,0,59.7,17.6,59.7,59.3v8.6c0,34-13.6,44.1-23.8,48.9,14.8,7.2,21.8,18.1,21.8,51.4s-.4,57.8,1.6,70.4h-44.4c-3-10.8-2.7-41.8-2.7-72.3s-3.1-31.4-20.5-31.4h-5.4,0ZM1403,102.5h5.7c15.3,0,21.6-4.8,21.6-27v-11.5c0-16-3.2-24-20.1-24h-7.2v62.5h0Z" />
                    <path d="M1547.1,3.8v238.5h-45.8V3.8h45.8Z" />
                    <path d="M1690.3,164.5v12.9c0,30.1-5.5,68.5-60.2,68.5s-57.9-21.5-57.9-65.4V63.6c0-41.5,20.7-63.3,58.9-63.3s58.6,33.2,58.6,64.4v15h-46.2v-20.4c0-13.1-2.8-19.8-12.4-19.8s-12.5,6.3-12.5,19.8v126.1c0,12.7,2.1,21.4,12.5,21.4s12.9-7.3,12.9-22.5v-19.8h46.4Z" />
                    <path d="M1750.7,168.8v16.5c0,15.7,3.9,23,14.4,23s13-10.8,13-22.1c0-22.5-4.2-29-28.6-49.4-26.9-22.8-40.7-36.2-40.7-71.4S1720.1,0,1765.3,0s55.1,32.7,55.1,60v13.7h-43.3v-14.3c0-14.5-2.4-21.6-11.4-21.6s-11.2,7.3-11.2,20.8,2.6,21.5,22.3,36.8c35.1,27.6,47.7,43,47.7,82.3s-12.8,68.1-60.1,68.1-58.5-27.4-58.5-62.8v-14.3h44.9,0Z" />
                    <path d="M0,3.8v120.8c0,66.6,54.2,120.8,120.8,120.8s120.8-54.2,120.8-120.8V3.8H0ZM206.2,124.6c0,47.1-38.3,85.4-85.4,85.4s-85.4-38.3-85.4-85.4V39.2h170.8v85.4h0Z" />
                    <polygon points="96.1 168.1 96.3 168.1 96.3 168.1 137.2 168.1 137.1 167.9 184.2 68.7 143.2 68.7 114.8 128.7 101.3 104.8 60.4 104.8 96.2 167.9 96.1 168.1" />
                </g>
            </g>
        </g>
    </svg>
);
