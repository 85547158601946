import { ManualScanConfirmationDialog } from '@components/ManualScanConfirmationDialog';
import { ScanStatusStartButton } from '@components/ScanStatus/ScanStatusStartButton';
import { useQuery } from '@tanstack/react-query';
import { Box, Text } from '@usercentrics/cookiebot-ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getScansDetails } from '../../api/api';
import { useAuthStore } from '../../stores';
import { ScanStatusLastScan } from './ScanStatusLastScan';
import { ScanStatusNextSchedule } from './ScanStatusNextSchedule';
import { ScanStatusViewScanBtn } from './ScanStatusViewScanBtn';

type Props = {
    status: string;
};

export const ScanStatusDone = ({ status }: Props) => {
    const { t } = useTranslation();
    const { appInstanceId } = useAuthStore();

    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
        useState(false);

    const { data } = useQuery({
        queryKey: ['getScansDetails', appInstanceId],
        queryFn: () => getScansDetails(appInstanceId),
        enabled: !!appInstanceId
    });

    const handleOnDialogClose = () => {
        setIsConfirmationDialogOpen(false);
    };

    const handleOnRescanClick = () => {
        setIsConfirmationDialogOpen(true);
    };

    return (
        <>
            <Box display="flex" flexDirection="column" gap="16px">
                <ScanStatusLastScan status={status} />
                <Text>
                    {t('dashboard.scanOverview.scanStatusDone.description')}
                </Text>
                <Box display="grid" gap="8px" gridTemplateColumns="1fr 1fr">
                    <ScanStatusViewScanBtn />
                    <ScanStatusStartButton
                        status={status}
                        hasIcon={false}
                        text={t('quotedScans.rescanButton')}
                        onClick={handleOnRescanClick}
                    />
                </Box>
                <ScanStatusNextSchedule />
            </Box>
            <ManualScanConfirmationDialog
                isOpen={isConfirmationDialogOpen}
                onClose={handleOnDialogClose}
                {...data}
            />
        </>
    );
};
