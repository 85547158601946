import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getSteps, setStepComplete } from '../api/api';
import {
    appConfigStoreActions,
    useAppConfigStore,
    useAuthStore,
    useReportStore
} from '../stores';
import { StatusEnum } from '../util/constants';
import { useDisplayOnboarding } from './useDisplayOnboarding';

enum ActionType {
    Redirect = 'redirect',
    Action = 'action'
}

type Action = {
    type: ActionType;
    handler: string | (() => void);
    disabled?: boolean;
};

export const useOnboarding = () => {
    const client = useQueryClient();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mutateShowOnboarding, showOnboardingIsLoading } =
        useDisplayOnboarding();
    const { isOnboardingEnabled } = useAppConfigStore();
    const { status } = useReportStore();
    const { domain } = useAuthStore();

    const {
        data,
        isLoading: isStepsLoading,
        isRefetching
    } = useQuery({
        queryKey: ['getSteps'],
        queryFn: getSteps,
        cacheTime: 0
    });

    const { mutate: setComplete, isLoading: isSetStepCompleteLoading } =
        useMutation({
            mutationFn: setStepComplete,
            cacheTime: 0,
            onSettled: () => {
                client.invalidateQueries(['getSteps']);
            }
        });

    const actionsMap: Record<number, Action> = useMemo(
        () => ({
            1: {
                type: ActionType.Redirect,
                handler: '/report',
                disabled: status !== StatusEnum.Done || !domain
            },
            2: {
                type: ActionType.Redirect,
                handler: '/banner/appearance'
            },
            3: {
                type: ActionType.Action,
                handler: () =>
                    appConfigStoreActions.setTriggerScriptChange(true),
                disabled: !domain
            }
        }),
        [domain, status]
    );

    const handleOnChange = (checked: boolean, stepId: string) => {
        setComplete({ stepId, value: checked });
    };

    const stepsCompleted = data?.filter((step: any) => step.isCompleted).length;

    const handleAction = (index: number) => {
        const action = actionsMap[index];

        if (action.disabled) {
            return;
        }

        if (
            action.type === ActionType.Redirect &&
            typeof action.handler === 'string'
        ) {
            navigate(action.handler);
        } else if (
            action.type === ActionType.Action &&
            typeof action.handler === 'function'
        ) {
            action.handler();
        }
    };

    const allStepsCompleted = data?.every((step: any) => step.isCompleted);

    const firstNotCompleted = data?.findIndex((step: any) => !step.isCompleted);

    const isLoading =
        isSetStepCompleteLoading ||
        isStepsLoading ||
        isRefetching ||
        showOnboardingIsLoading;

    return {
        mutateShowOnboarding,
        data,
        handleOnChange,
        stepsCompleted,
        handleAction,
        allStepsCompleted,
        firstNotCompleted,
        isLoading,
        t,
        isOnboardingEnabled,
        actionsMap
    };
};
