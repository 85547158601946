import { Switch } from '@chakra-ui/react';
import styles from '@components/BannerPreview/BannerPreview.module.css';
import { Settings } from '@components/BannerPreview/types';
import { getContrastingColor } from '@components/BannerPreview/utils';
import { Box, Button, Text } from '@usercentrics/cookiebot-ui-react';
import { CSSProperties } from 'react';

type Props = {
    settings: Settings;
};

export const BannerCenterPreview = ({ settings }: Props) => {
    const { generalColors, advancedSettings, buttonColors, toggleColors } =
        settings;

    return (
        <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            w="70%"
            h="382px"
            bg={generalColors.backgroundColor!}
            zIndex={100}
            borderRadius="8px"
            boxShadow={
                advancedSettings.useBackgroundShadow
                    ? '0 32px 68px rgba(0, 0, 0, .3)'
                    : ''
            }
            maxWidth="900px"
        >
            <Box
                p="24px"
                display="flex"
                flexDirection="column"
                h="100%"
                justifyContent="space-between"
            >
                <Box display="grid" justifyContent="end">
                    <Box>
                        <Text
                            style={{
                                color: getContrastingColor(
                                    settings.generalColors.backgroundColor!
                                )
                            }}
                            fontSize="11px"
                            textAlign="center"
                        >
                            Powered by Usercentrics Consent Management
                        </Text>
                    </Box>
                </Box>
                <Box>
                    <Text fontWeight={600} color={generalColors.textColor!}>
                        Title text
                    </Text>

                    <Text
                        className={styles.overflowText}
                        color={generalColors.textColor!}
                    >
                        This site uses third-party website tracking technologies
                        to provide and continually improve our services, and to
                        display advertisements according to users interests. I
                        agree and may revoke or change my consent at any time
                        with effect for the future.
                    </Text>
                    <Box
                        color={generalColors.linkColor!}
                        fontWeight={600}
                        mt="8px"
                    >
                        Link
                    </Box>
                </Box>
                <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr 1fr 1fr"
                    borderTop="1px solid #D6D6D6"
                    borderBottom="1px solid #D6D6D6"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        gap="8px"
                        borderRight="1px solid #D6D6D6"
                        py="8px"
                        textAlign="center"
                    >
                        <Text color={generalColors.textColor!} fontWeight={600}>
                            Disabled
                        </Text>
                        <Switch
                            isDisabled
                            isChecked
                            className={styles.customDisabledSwitch}
                            style={
                                {
                                    '--background-color':
                                        toggleColors.disabledToggleBackground,
                                    '--icon-color':
                                        toggleColors.disabledToggleIcon
                                } as CSSProperties
                            }
                        />
                    </Box>
                    {[1, 2, 3].map((index) => (
                        <Box
                            key={index}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            gap="8px"
                            borderRight={index === 3 ? '' : '1px solid #D6D6D6'}
                            textAlign="center"
                        >
                            <Text
                                color={generalColors.textColor!}
                                fontWeight={600}
                            >
                                Category {index}
                            </Text>
                            <Switch
                                isChecked={index === 1}
                                className={styles.customSwitch}
                                style={
                                    {
                                        '--active-background-color':
                                            toggleColors.activeToggleBackground,
                                        '--active-icon-color':
                                            toggleColors.activeToggleIcon,
                                        '--inactive-background-color':
                                            toggleColors.inactiveToggleBackground,
                                        '--inactive-icon-color':
                                            toggleColors.inactiveToggleIcon
                                    } as CSSProperties
                                }
                            />
                        </Box>
                    ))}
                </Box>
                <Box
                    display="flex"
                    w="100%"
                    gap="8px"
                    justifyContent="space-between"
                >
                    <Button
                        w="100%"
                        bg={buttonColors.saveButtonBackground!}
                        _hover={{}}
                    >
                        <Text color={buttonColors.saveButtonText!}>
                            Save Settings
                        </Text>
                    </Button>
                    <Button
                        w="100%"
                        bg={buttonColors.denyButtonBackground!}
                        _hover={{}}
                    >
                        <Text color={buttonColors.denyButtonText!}>Deny</Text>
                    </Button>
                    <Button
                        w="100%"
                        bg={buttonColors.acceptButtonBackground!}
                        _hover={{}}
                    >
                        <Text color={buttonColors.acceptButtonText!}>
                            Accept All
                        </Text>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
