import { useMutation, useQueryClient } from '@tanstack/react-query';

import { setShowOnboarding } from '../api/api';

export const useDisplayOnboarding = () => {
    const client = useQueryClient();
    const { mutate: mutateShowOnboarding, isLoading: showOnboardingIsLoading } =
        useMutation({
            mutationFn: setShowOnboarding,
            cacheTime: 0,
            onSettled: () => {
                client.invalidateQueries(['getShowOnboarding']);
            }
        });

    return {
        mutateShowOnboarding,
        showOnboardingIsLoading
    };
};
