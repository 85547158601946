import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { ScanParams, startScan } from '../api/api';
import { reportStoreActions, useAuthStore } from '../stores';
import { StatusEnum } from '../util/constants';

export const useStartScan = () => {
    const { domain, appInstanceId } = useAuthStore();

    const client = useQueryClient();

    const startScanParams: ScanParams = useMemo(
        () => ({
            appInstanceId,
            domainUrl: domain
        }),
        [domain, appInstanceId]
    );

    const { mutate } = useMutation({
        mutationFn: startScan,
        onSuccess: () => {
            client.invalidateQueries([
                'scanReport',
                {
                    appInstanceId,
                    domainUrl: domain
                }
            ]);
        }
    });

    const handleStartScan = useCallback(() => {
        reportStoreActions.setStatus(StatusEnum.Pending);

        if (appInstanceId === 'Test') {
            return;
        }

        mutate(startScanParams);
    }, [appInstanceId, mutate, startScanParams]);

    return {
        handleStartScan
    };
};
