import { FaqSection } from '@components/FaqSection';
import { Onboarding } from '@components/Onboarding';
import { ScanStatus } from '@components/ScanStatus';
import { Box, Flex, Stack, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { useAppConfigStore } from '../stores';

export const Dashboard = () => {
    const { t } = useTranslation();
    const { appError } = useAppConfigStore();

    return (
        <Stack spacing="16px">
            <Flex flexDirection="column">
                <Stack spacing="16px">
                    <Onboarding />
                    <Box w="100%">
                        <Text fontSize={21} fontWeight={600} mb="16px">
                            {t('dashboard.scanOverview.title')}
                        </Text>
                        <Box
                            bg={appError ? '#9F1818' : 'gray.50'}
                            borderRadius="lg"
                            py={6}
                            px={12}
                            w="100%"
                            alignSelf="flex-start"
                        >
                            <ScanStatus />
                        </Box>
                    </Box>
                    <Box w="100%">
                        <FaqSection />
                    </Box>
                </Stack>
            </Flex>
        </Stack>
    );
};
