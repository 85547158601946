import { BannerBottomPreview } from '@components/BannerPreview/BannerBottomPreview';
import { BannerCenterPreview } from '@components/BannerPreview/BannerCenterPreview';
import { themes } from '@components/BannerPreview/constants';
import { Settings } from '@components/BannerPreview/types';
import {
    Box,
    Browser,
    BrowserBar,
    BrowserPanel
} from '@usercentrics/cookiebot-ui-react';
import { useEffect, useState } from 'react';

import { ConsentBannerFormState } from '../../pages/Appearance/types/consentBanner.type';
import { PrivacyTriggerFormState } from '../../pages/Appearance/types/privacyTrigger.type';

type Props = {
    consentBannerFormState: ConsentBannerFormState;
    privacyTriggerFormState: PrivacyTriggerFormState;
    height?: string;
};

export const BannerPreview = ({
    consentBannerFormState,
    privacyTriggerFormState,
    height = '900'
}: Props) => {
    const { theme } = consentBannerFormState;

    const [settings, setSettings] = useState<Settings>({
        ...themes.default.consentBanner,
        ...themes.default.privacyTrigger,
        layoutType: consentBannerFormState.layoutType
    } as Settings);

    const { advancedSettings, layoutType } = settings;

    useEffect(() => {
        if (theme !== 'custom') {
            setSettings({
                ...themes[theme].consentBanner,
                ...themes[theme].privacyTrigger,
                layoutType: consentBannerFormState.layoutType
            } as Settings);

            return;
        }
        setSettings({ ...consentBannerFormState, ...privacyTriggerFormState });
    }, [consentBannerFormState, privacyTriggerFormState, theme]);

    return (
        <Browser>
            <BrowserBar />
            <BrowserPanel height={height}>
                {advancedSettings.backgroundOverlay &&
                advancedSettings.overlayOpacity ? (
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        backgroundColor="#333333"
                        opacity={
                            parseInt(advancedSettings.overlayOpacity, 10) / 100
                        }
                        zIndex={0}
                    />
                ) : null}
                {layoutType === 'banner' ? (
                    <BannerBottomPreview settings={settings} />
                ) : (
                    <BannerCenterPreview settings={settings} />
                )}
            </BrowserPanel>
        </Browser>
    );
};
