import { proxy, useSnapshot } from 'valtio';

export type AppError = {
    hasError: boolean;
    reason?: string;
};

type State = {
    appLoading: boolean;
    drawerOpen: boolean;
    isScreenSmall: boolean;
    isScriptEnabled: boolean;
    appError: AppError | null;
    isFeedbackModalOpen: boolean;
    triggerScriptChange: boolean;
    isOnboardingEnabled: boolean;
};

const defaultState: State = {
    appLoading: true,
    drawerOpen: false,
    isScreenSmall: false,
    isScriptEnabled: false,
    appError: null,
    isFeedbackModalOpen: false,
    triggerScriptChange: false,
    isOnboardingEnabled: true
};

const state = proxy<State>(defaultState);

export const appConfigStoreActions = {
    setAppLoading: (appLoading: boolean) => {
        state.appLoading = appLoading;
    },
    setDrawerOpen: (drawerOpen: boolean) => {
        state.drawerOpen = drawerOpen;
    },
    setIsScreenSmall: (isScreenSmall: boolean) => {
        state.isScreenSmall = isScreenSmall;
    },
    setIsScriptEnabled: (isScriptEnabled: boolean) => {
        state.isScriptEnabled = isScriptEnabled;
    },
    setAppError: (appError: AppError | null) => {
        state.appError = appError;
    },
    setIsFeedbackModalOpen: (isFeedbackModalOpen: boolean) => {
        state.isFeedbackModalOpen = isFeedbackModalOpen;
    },
    setTriggerScriptChange: (value: boolean) => {
        state.triggerScriptChange = value;
    },
    setIsOnboardingEnabled: (value: boolean) => {
        state.isOnboardingEnabled = value;
    }
};

export const useAppConfigStore = () => useSnapshot<State>(state);
