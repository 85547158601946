type Props = {
    fill?: string;
};

export const ShieldBlackSvg = ({ fill = '#000000' }: Props) => (
    <svg
        id="Layer_2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 241.66 241.66"
        width="50px"
        height="50px"
    >
        <g id="Design" fill={fill}>
            <g id="Design-2" data-name="Design">
                <path d="M0,0v120.83c0,66.63,54.2,120.83,120.83,120.83s120.83-54.21,120.83-120.83V0H0ZM206.26,120.82c0,47.1-38.32,85.42-85.43,85.42s-85.42-38.32-85.42-85.42V35.4h170.85v85.42Z" />
                <polygon points="96.14 164.35 96.31 164.35 96.31 164.35 137.23 164.35 137.14 164.19 184.18 64.99 143.25 64.99 114.83 124.93 101.28 101.09 60.36 101.09 96.22 164.19 96.14 164.35" />
            </g>
        </g>
    </svg>
);
