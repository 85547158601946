import { BoltSvg } from '@assets/BoltSvg';
import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';
import { MdLaunch } from 'react-icons/md';

import { useAppConfigStore, useAuthStore, usePremiumStore } from '../../stores';

export const UpgradePremiumModal = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'upgradeModal' });
    const { appLoading } = useAppConfigStore();
    const { isPremium, trial } = usePremiumStore();
    const { appId, siteId } = useAuthStore();

    const onClose = () => {};

    const pricingPlanLink = `https://manage.wix.com/app-pricing-plans/${appId}/plan?meta-site-id=${siteId}&origin=manage_apps_bizmgr`;

    return (
        <Modal
            isOpen={isPremium !== null && !isPremium && !appLoading}
            onClose={onClose}
            id="upgrade-premium-modal"
        >
            <ModalOverlay />
            <ModalContent px="32px" pt="24px" pb="40px">
                <ModalHeader>
                    <Box display="flex" alignItems="center" gap="8px">
                        <BoltSvg />
                        {t('title')}
                    </Box>
                </ModalHeader>
                <ModalBody>
                    <Stack
                        spacing="24px"
                        textAlign="center"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        px="24px"
                        py="24px"
                    >
                        <Text
                            fontSize="21px"
                            fontWeight={600}
                            lineHeight="30px"
                        >
                            {t('subTitle')}
                        </Text>
                        <Button as="a" href={pricingPlanLink} target="_blank">
                            <Box display="flex" gap="8px" alignItems="center">
                                {trial?.available
                                    ? t('startFreeTrial')
                                    : t('upgradeWixPremiumGuide')}
                                <MdLaunch size="24px" />
                            </Box>
                        </Button>
                    </Stack>
                </ModalBody>
                <ModalFooter />
            </ModalContent>
        </Modal>
    );
};
