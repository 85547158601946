import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { getShowOnboarding } from '../api/api';
import {
    appConfigStoreActions,
    useAppConfigStore,
    useAuthStore,
    useClientSettingsStore,
    usePremiumStore,
    useReportStore
} from '../stores';

export const useAppConfig = () => {
    const { isPremium, isPremiumLoading } = usePremiumStore();
    const { clientSettingsId, clientSettingsLoading } =
        useClientSettingsStore();
    const { reportData } = useReportStore();
    const { isLoading: isAuthLoading } = useAuthStore();
    const { isLoading: isReportLoading } = useReportStore();
    const { appError } = useAppConfigStore();

    const { data: showOnboardingData, isLoading: isShowOnboardingLoading } =
        useQuery({
            queryKey: ['getShowOnboarding'],
            queryFn: getShowOnboarding,
            cacheTime: 0,
            enabled: !!clientSettingsId && !isAuthLoading
        });

    useEffect(() => {
        if (showOnboardingData) {
            appConfigStoreActions.setIsOnboardingEnabled(
                showOnboardingData.showOnboardingBanner
            );
        }
    }, [showOnboardingData]);

    useEffect(() => {
        appConfigStoreActions.setAppLoading(
            !appError &&
                (isAuthLoading ||
                    isReportLoading ||
                    isPremiumLoading ||
                    !clientSettingsId ||
                    clientSettingsLoading ||
                    isShowOnboardingLoading)
        );
    }, [
        clientSettingsId,
        clientSettingsLoading,
        appError,
        isAuthLoading,
        isPremium,
        isPremiumLoading,
        isReportLoading,
        reportData,
        isShowOnboardingLoading
    ]);
};
