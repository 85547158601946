import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppConfigStore, usePremiumStore, useReportStore } from '../stores';

export const useRoot = () => {
    const { status } = useReportStore();
    const { appLoading } = useAppConfigStore();
    const { isPremium } = usePremiumStore();
    const navigate = useNavigate();

    const isInitial = useRef<boolean>(true);
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        if (isPremium === false && !appLoading) {
            navigate('/dashboard');
            setShowContent(true);
        }
    }, [appLoading, isPremium, navigate]);

    useEffect(() => {
        if (!appLoading && isInitial.current) {
            isInitial.current = false;
            setShowContent(true);
            navigate('/dashboard');
        }
    }, [appLoading, navigate, status]);

    return {
        showContent
    };
};
