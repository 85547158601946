import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    ListItem,
    Text,
    UnorderedList
} from '@usercentrics/cookiebot-ui-react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

type Props = {
    isOtherServices: boolean;
};

export const OtherServicesBanner = ({ isOtherServices }: Props) =>
    isOtherServices ? (
        <Accordion
            allowToggle
            reduceMotion
            borderRadius="2xl"
            bg="blue.50"
            marginBottom={6}
        >
            <AccordionItem background="transparent">
                <AccordionButton display="flex" justifyContent="space-between">
                    <Text
                        fontWeight={600}
                        display="flex"
                        alignItems="center"
                        gap={2}
                        color="blue"
                    >
                        <AiOutlineQuestionCircle size={24} />
                        Why do I have &quot;Other services&quot;?
                    </Text>
                    <AccordionIcon color="blue" />
                </AccordionButton>
                <AccordionPanel>
                    <Box>
                        &quot;Other services&quot; are things that run on your
                        site, that we are not sure what is.
                    </Box>
                    <Box>
                        It can be something regular, that suddenly changed or it
                        can be something that is used by very few sites, for
                        example your own code.
                    </Box>
                    <br />
                    <Box>
                        You can look at the URL of the service and try to
                        determine what it may be:
                    </Box>
                    <UnorderedList>
                        <ListItem>
                            If a service looks to be one, that you already have
                            (e.g. youtube.com and you have &quot;Video Player -
                            Youtube&quot;), then it should likely be addressed
                            by the Usercentrics Team shortly and mapped to the
                            correct service. This may be because the service
                            provider altered something unexpected. You cannot do
                            anything about it.
                        </ListItem>
                        <ListItem>
                            If a service does not look like it comes from a
                            service, that you already have (e.g. youtube.com)
                            then it might be a script or code, you added
                            manually in Wix and you can adjust the category in
                            the Wix Dashboard where you add the code.
                        </ListItem>
                        <ListItem>
                            There is also the uncommon scenario, that the
                            service is neither of the above. In that case, you
                            cannot do anything and will have to wait for the
                            Usercentrics Team to address the service
                            appropriately.
                        </ListItem>
                    </UnorderedList>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    ) : null;
