import { proxy, useSnapshot } from 'valtio';

import { Trial } from '../../api/api';

type State = {
    isPremium: boolean | null;
    isPremiumLoading: boolean;
    trial: Trial | null;
};

const defaultState: State = {
    isPremium: null,
    isPremiumLoading: false,
    trial: null
};

const state = proxy(defaultState);

export const premiumStoreActions = {
    setIsPremium: (isPremium: boolean | null) => {
        state.isPremium = isPremium;
    },
    setIsPremiumLoading: (isPremiumLoading: boolean) => {
        state.isPremiumLoading = isPremiumLoading;
    },
    setTrial: (trial: Trial | null) => {
        state.trial = trial;
    }
};

export const usePremiumStore = () => useSnapshot<State>(state);
