import { useMutation } from '@tanstack/react-query';
import { Button } from '@usercentrics/cookiebot-ui-react';

import { resetCountScans } from '../../api/api';
import { useAuthStore } from '../../stores';

export const ResetScansCount = () => {
    const { appInstanceId } = useAuthStore();
    const { mutate: resetCount } = useMutation({
        mutationFn: resetCountScans,
        cacheTime: 0
    });

    return (
        <Button onClick={() => resetCount(appInstanceId)}>
            Reset scans count
        </Button>
    );
};
