import { Button } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { useAuthStore } from '../../stores';

export const VisitWebsiteButton = () => {
    const { t } = useTranslation();
    const { domain } = useAuthStore();

    return domain ? (
        <Button
            width="clamp(60px, 10vw, 90px)"
            variant="outline"
            as="a"
            href={domain}
            target="_blank"
        >
            {t('common.visitSite')}
        </Button>
    ) : null;
};
