import { Box, Container } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { useAmplitude } from '../../hooks';
import { LanguageSelect } from '../LanguageSelect/LanguageSelect';

export const Footer = () => {
    const currentYear = new Date().getFullYear();
    const { t } = useTranslation();
    const { sendEvent } = useAmplitude();

    const handleOnClick = (title: string) => {
        sendEvent(`${title} clicked - Footer menu`);
    };

    return (
        <Box as="footer" py="12px" borderTopWidth={1} borderColor="grey.200">
            <Container
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box fontSize="xs" color="#666666">
                    © {currentYear} Usercentrics for Wix -{' '}
                    <Box
                        as="a"
                        color="#1032CF"
                        fontWeight={600}
                        href="https://www.cookiebot.com/en/wix-terms-of-services"
                        target="_blank"
                        onClick={() => handleOnClick('Legal')}
                    >
                        {t('common.legal')}
                    </Box>{' '}
                    -{' '}
                    <Box
                        as="a"
                        color="#1032CF"
                        fontWeight={600}
                        href="https://www.cookiebot.com/en/wix-privacy-policy"
                        target="_blank"
                        onClick={() => handleOnClick('Privacy')}
                    >
                        {t('common.privacy')}
                    </Box>{' '}
                </Box>
                <Box>
                    <LanguageSelect />
                </Box>
            </Container>
        </Box>
    );
};
