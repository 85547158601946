import { Progress, Skeleton } from '@chakra-ui/react';
import { Box } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

type Props = {
    stepsCompleted: number;
    isDataPresent: boolean;
    numberOfSteps: number;
};

export const OnboardingProgress = ({
    stepsCompleted,
    isDataPresent,
    numberOfSteps
}: Props) => {
    const { t } = useTranslation();

    return (
        <Box display="flex" justifyContent="end" alignItems="center">
            {!isDataPresent ? (
                <Skeleton
                    height="30px"
                    width="300px"
                    borderRadius="8px"
                    startColor="gray.50"
                    endColor="gray.200"
                />
            ) : (
                <Box>
                    {stepsCompleted}/{numberOfSteps}{' '}
                    {t('onboarding.stepsCompleted')}
                    <Progress
                        size="sm"
                        value={Math.round(
                            (stepsCompleted / numberOfSteps) * 100
                        )}
                        color="#1A32CF"
                    />
                </Box>
            )}
        </Box>
    );
};
