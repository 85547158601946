export const getContrastingColor = (color: string) => {
    // Remove the leading # if it's present
    const hexColor = color.replace('#', '');

    // Parse the r, g, b values
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return black for light colors and white for dark colors
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
};
