import { Box, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';
import { FaClock } from 'react-icons/fa';

import { usePremiumStore } from '../../stores';
import { Colors } from '../../util/constants';

export const FreeTrialDetailsBox = () => {
    const { t } = useTranslation();
    const { trial } = usePremiumStore();

    if (!trial?.active) {
        return null;
    }

    const daysLeft = trial?.endDate
        ? Math.ceil(
              (new Date(trial.endDate).getTime() - Date.now()) /
                  (1000 * 60 * 60 * 24)
          )
        : 0;

    return (
        <Box
            p="8px"
            bg="#DFE6F8"
            borderRadius="8px"
            display="flex"
            alignItems="center"
            gap="8px"
        >
            <FaClock color={Colors.DefaultBlue} />
            <Text color={Colors.DefaultBlue} fontWeight={600}>
                {t('common.freeTrialDaysLeft', { daysLeft })}
            </Text>
        </Box>
    );
};
