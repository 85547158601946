import { LogoBlack } from '@assets/LogoBlack';
import { FreeTrialDetailsBox } from '@components/FreeTrialDetailsBox';
import { Box, Container } from '@usercentrics/cookiebot-ui-react';
import { NavigateFunction } from 'react-router-dom';

import { Notifications } from '../Notifications';

export interface Props {
    navigate: NavigateFunction;
}

export const Header = ({ navigate }: Props) => (
    <Box as="header" py={4} borderBottomWidth={1} borderColor="grey.200">
        <Container>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                bg="FDFDFD"
            >
                <LogoBlack />
                <Box display="flex" alignItems="center" gap="8px">
                    <FreeTrialDetailsBox />
                    <Notifications navigate={navigate} />
                </Box>
            </Box>
        </Container>
    </Box>
);
