import { Divider } from '@chakra-ui/react';
import { AppInitializationError } from '@components/AppInitializationError';
import { ResetScansCount } from '@components/ResetScansCount';
import { ScanAnySite } from '@components/ScanAnySite';
import { Box, Flex } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import {
    useAdminStore,
    useAppConfigStore,
    useAuthStore,
    useReportStore
} from '../../stores';
import { StatusEnum } from '../../util/constants';
import { ScanStatusDone } from './ScanStatusDone';
import { ScanStatusError } from './ScanStatusError';
import { ScanStatusPending } from './ScanStatusPending';
import { ScanStatusStartButton } from './ScanStatusStartButton';

export const ScanStatus = () => {
    const { t } = useTranslation();
    const { status, isLowRisk, error } = useReportStore();
    const { appError } = useAppConfigStore();

    const { isAdmin } = useAdminStore();
    const { domain } = useAuthStore();

    const risk = isLowRisk ? StatusEnum.DoneLowRisk : StatusEnum.DoneAtRisk;

    const calculatedStatus = status !== StatusEnum.Done ? status : `${risk}`;

    const isScanning = status === StatusEnum.Pending;

    if (appError) {
        return <AppInitializationError appError={appError} />;
    }

    return (
        <Flex direction="column" gap={5}>
            {!domain ? (
                <Box>{t('common.noDomain')}</Box>
            ) : (
                <>
                    {isScanning ? <ScanStatusPending /> : null}
                    {status === StatusEnum.Done ? (
                        <ScanStatusDone status={calculatedStatus} />
                    ) : null}
                    {error && !isScanning ? <ScanStatusError /> : null}
                </>
            )}

            {isAdmin ? (
                <>
                    <Divider />
                    <ScanStatusStartButton status={status} />
                    <ScanAnySite />
                    <ResetScansCount />
                </>
            ) : null}
        </Flex>
    );
};
