import { Skeleton } from '@chakra-ui/react';
import { Loader } from '@components/Loader';
import { OnboardingProgress } from '@components/Onboarding/OnboardingProgress';
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Checkbox,
    Stack,
    Text
} from '@usercentrics/cookiebot-ui-react';

import { useOnboarding } from '../../hooks/useOnboarding';

export const Onboarding = () => {
    const {
        isOnboardingEnabled,
        mutateShowOnboarding,
        data,
        handleOnChange,
        stepsCompleted,
        handleAction,
        allStepsCompleted,
        firstNotCompleted,
        isLoading,
        t,
        actionsMap
    } = useOnboarding();

    if (!isOnboardingEnabled) {
        return null;
    }

    return (
        <Stack spacing="16px">
            <Box>
                <Text fontSize="24px" fontWeight={600}>
                    {t('onboarding.title')}
                </Text>
                <Text>{t('onboarding.description')}</Text>
            </Box>
            <OnboardingProgress
                stepsCompleted={stepsCompleted}
                isDataPresent={!!data?.length}
                numberOfSteps={data?.length}
            />
            {data?.length ? (
                <Stack
                    spacing={2}
                    bg={isLoading ? 'gray.100' : 'white'}
                    position="relative"
                >
                    {isLoading ? (
                        <Box
                            position="absolute"
                            left="50%"
                            top="50%"
                            transform="translate(-50%, -50%)"
                            zIndex={1001}
                        >
                            <Loader />
                        </Box>
                    ) : null}
                    <Accordion
                        allowToggle
                        reduceMotion
                        defaultIndex={firstNotCompleted}
                    >
                        <Stack spacing={2}>
                            {data?.map((step: any, index: number) => (
                                <AccordionItem
                                    key={step.id}
                                    boxShadow="0 4px 6px 1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
                                    border="1px solid #E2E8F0"
                                    borderRadius="8px"
                                    overflowWrap="anywhere"
                                    bg={
                                        step.isCompleted
                                            ? '#1a32cf24'
                                            : 'transparent'
                                    }
                                >
                                    <AccordionButton
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="space-between"
                                        bg="transparent"
                                        gap="8px"
                                        px="24px"
                                        py="16px"
                                    >
                                        <Text>
                                            {index + 1}.{' '}
                                            {t(
                                                `onboarding.step${
                                                    index + 1
                                                }.title`
                                            )}
                                        </Text>
                                        {step.isCompleted ? (
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap="8px"
                                            >
                                                <Text
                                                    fontStyle="italic"
                                                    fontWeight={400}
                                                >
                                                    {t('onboarding.done')}
                                                </Text>
                                            </Box>
                                        ) : null}
                                    </AccordionButton>
                                    <AccordionPanel borderTopWidth={1}>
                                        <Stack spacing="16px">
                                            <Text>
                                                {t(
                                                    `onboarding.step${
                                                        index + 1
                                                    }.description`
                                                )}
                                            </Text>
                                            <Box>
                                                <Button
                                                    variant="outline"
                                                    onClick={() =>
                                                        handleAction(index + 1)
                                                    }
                                                    isDisabled={
                                                        actionsMap?.[index + 1]
                                                            ?.disabled
                                                    }
                                                >
                                                    {t(
                                                        `onboarding.step${
                                                            index + 1
                                                        }.action`
                                                    )}
                                                </Button>
                                            </Box>
                                            <Box
                                                display="flex"
                                                justifyContent="end"
                                            >
                                                <Checkbox
                                                    defaultChecked={
                                                        step.isCompleted
                                                    }
                                                    onChange={(event) =>
                                                        handleOnChange(
                                                            event.target
                                                                .checked,
                                                            step.id
                                                        )
                                                    }
                                                >
                                                    <Text>
                                                        {t(
                                                            'onboarding.markAsDone'
                                                        )}
                                                    </Text>
                                                </Checkbox>
                                            </Box>
                                        </Stack>
                                    </AccordionPanel>
                                </AccordionItem>
                            ))}
                        </Stack>
                    </Accordion>
                </Stack>
            ) : null}
            {!data?.length ? (
                <Stack>
                    {[1, 2, 3, 4, 5].map((value) => (
                        <Skeleton
                            key={value}
                            height="50px"
                            borderRadius="8px"
                            startColor="gray.50"
                            endColor="gray.200"
                        />
                    ))}
                </Stack>
            ) : null}
            <Box display="flex" justifyContent="end">
                {!data?.length ? (
                    <Skeleton
                        height="30px"
                        width="300px"
                        borderRadius="8px"
                        startColor="gray.50"
                        endColor="gray.200"
                    />
                ) : (
                    <Button
                        variant={allStepsCompleted ? 'solid' : 'transparent'}
                        size={'sm' as any}
                        onClick={() => mutateShowOnboarding(false)}
                    >
                        {allStepsCompleted
                            ? t('onboarding.completeButtonText')
                            : t('onboarding.skipOnboarding')}
                    </Button>
                )}
            </Box>
        </Stack>
    );
};
