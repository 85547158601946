import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getBannerScript, setScriptSettingsId } from '../api/api';
import {
    appConfigStoreActions,
    useAppConfigStore,
    useAuthStore,
    useClientSettingsStore
} from '../stores';
import { useAmplitude } from './useAmplitude';

export const useBannerSwitch = () => {
    const { appInstanceId } = useAuthStore();
    const { clientSettingsId } = useClientSettingsStore();
    const { isScriptEnabled, triggerScriptChange } = useAppConfigStore();
    const toast = useToast();
    const { t } = useTranslation();
    const { sendEvent } = useAmplitude();

    const [isLoading, setIsLoading] = useState(false);

    const isInitialLoad = useRef(true);

    const { mutate } = useMutation({
        mutationFn: setScriptSettingsId,
        onSuccess: () => {
            toast({
                title: t('bannerSwitch.successMessage'),
                status: 'info',
                duration: 3000,
                isClosable: true
            });
        },
        onError: () => {
            toast({
                title: t('bannerSwitch.errorMessage'),
                status: 'error',
                duration: 9000,
                isClosable: true
            });
            appConfigStoreActions.setIsScriptEnabled(false);
        },
        onSettled: () => {
            setIsLoading(false);
        }
    });

    const { data, refetch } = useQuery({
        queryKey: ['getScript', appInstanceId],
        queryFn: () => getBannerScript({ appInstanceId }),
        enabled:
            appInstanceId !== '' &&
            appInstanceId !== null &&
            appInstanceId !== undefined
    });

    useEffect(() => {
        if (data?.properties && isInitialLoad.current) {
            appConfigStoreActions.setIsScriptEnabled(!data.properties.disabled);
            isInitialLoad.current = false;
        }
    }, [data]);

    const handleSwitchChange = useCallback(
        async (event: ChangeEvent<HTMLInputElement>) => {
            setIsLoading(true);
            const disabled = !event.target.checked;
            appConfigStoreActions.setIsScriptEnabled(event.target.checked);
            appConfigStoreActions.setTriggerScriptChange(event.target.checked);
            const { data: reFetchData } = await refetch();
            sendEvent(disabled ? 'Banner Disabled' : 'Banner Enabled');
            mutate({
                settingsId: clientSettingsId as string,
                dataCacheVersion:
                    reFetchData?.properties.parameters
                        .RequestDataCacheVersion ?? '0',
                appInstanceId,
                disabled
            });
        },
        [appInstanceId, clientSettingsId, mutate, refetch, sendEvent]
    );

    useEffect(() => {
        if (triggerScriptChange && triggerScriptChange !== isScriptEnabled) {
            handleSwitchChange({
                target: { checked: triggerScriptChange }
            } as any);
        }
    }, [handleSwitchChange, isScriptEnabled, triggerScriptChange]);

    return {
        handleSwitchChange,
        isScriptEnabled,
        isLoading
    };
};
